import React, { useEffect, useState } from 'react';
import { Collapse, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import navLinks from '../navLinks/Navlinks';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import smartsaIcon from '../assets/smartsa_icon_svg.svg'
import axios from 'axios';
import '../sidenav/Sidenav.css'
import {ReactComponent as LogoutIcon} from '../assets/logout-icon.svg'
import { useLocalStorage } from '@uidotdev/usehooks';
import isDesktop from '../isDesktop';

const Sidebar = ({ isOpen, onClose,setLogoutDialog }) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openLead, setOpenLead] = React.useState(false);
  const [openManage, setOpenManage] = React.useState(false);
  const [empName, setEmpName] = useState('')
  const userId = localStorage.getItem('userId');
  const [token,setToken] = useLocalStorage("token",null)

  const handleNavigation = (route) => {
    navigate(route);
    onClose();
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickLead = () => {
    setOpenLead(!openLead);
  };

  const handleClickManage = () => {
    setOpenManage(!openManage);
  };

  const adminSection = navLinks.find(link => link.tag === "Admin");
  const adminTabs = adminSection ? adminSection.tabs : [];

  const leadsSection = navLinks.find(link => link.tag === "Lead Management System");
  const leadTabs = leadsSection ? leadsSection.tabs : [];

  const ContentSection = navLinks.find(link => link.tag === "Content Management System");
  const contentTabs = ContentSection ? ContentSection.tabs : [];

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userRole');
    navigate('/');
    
  };

  useEffect(() => {
    const fetchEmployeeName = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/auth/employees/${userId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });        
        setEmpName(response.data.data.emp_name || '');
        console.log(response.data.data.emp_name, " got the emp name");
      } catch (error) {
        console.error('Failed to fetch employee name:', error);
      }
    };

    if (userId && token) {
      fetchEmployeeName();
    }
  }, [userId]);
  const isDesktopDevice = isDesktop();
  return (
    <Drawer anchor="left" open={isOpen} onClose={onClose} PaperProps={{sx: !isDesktopDevice && {width: !isDesktopDevice && '11.25rem', backgroundColor: "#EAF1F7", top: !isDesktopDevice && '2.67rem', height: !isDesktopDevice && 'auto', bottom: !isDesktopDevice && '0', borderRadius: !isDesktopDevice && '0 4px 4px 0', overflowX: !isDesktopDevice && 'hidden'}}}>
      <div className='sidenav-top'>
      <div className='sidenav-name'>Hi, {empName}</div>
      <Link to="/dashboard" style={{ textDecoration: 'none' }}>
            <Typography component="div" sx={{ flexGrow: 1, marginRight: !isDesktopDevice && '-15px' }}>
              <img src={smartsaIcon} alt="Logo"/>
            </Typography>
          </Link>
      </div>
      <div style={{display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between'}}>
        <div>
          <List sx={{ paddingBottom: '0', paddingTop: '0' }}>
            <ListItemButton onClick={handleClick} style={{ padding: '0' }}>
              <ListItem className='list-item'>
                <ListItemText disableTypography className='list-item-text'>Admin</ListItemText>
                {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </ListItem>
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {adminTabs.map((tab) => (
                <List component="div" key={tab.id} sx={{ paddingBottom: '0', paddingTop: '0' }}>
                  <ListItemButton onClick={() => handleNavigation(tab.route)} style={{ padding: '0' }}>
                    <ListItem className='list-item'>
                      <ListItemText disableTypography sx={{ fontSize: isDesktopDevice ? "0.769vw" : "0.688rem" }}>{tab.title}</ListItemText>
                    </ListItem>
                  </ListItemButton>
                </List>
              ))}
            </Collapse>
          </List>
          <List sx={{ paddingBottom: '0', paddingTop: '0' }}>
            <ListItemButton onClick={handleClickLead} style={{ padding: '0' }}>
              <ListItem className='list-item'>
                <ListItemText disableTypography className='list-item-text'>Lead Management <br /> System</ListItemText>
                {openLead ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </ListItem>
            </ListItemButton>
            <Collapse in={openLead} timeout="auto" unmountOnExit>
              {leadTabs.map((tab) => (
                <List component="div" key={tab.id} sx={{ paddingBottom: '0', paddingTop: '0' }}>
                  <ListItemButton onClick={() => handleNavigation(tab.route)} style={{ padding: '0' }}>
                    <ListItem className='list-item'>
                      <ListItemText disableTypography sx={{ fontSize: isDesktopDevice ? "0.769vw" : "0.688rem" }}>{tab.title}</ListItemText>
                    </ListItem>
                  </ListItemButton>
                </List>
              ))}
            </Collapse>
          </List>
          <List sx={{ paddingBottom: '0', paddingTop: '0' }}>
            <ListItemButton onClick={handleClickManage} style={{ padding: '0' }}>
              <ListItem className='list-item'>
                <ListItemText disableTypography className='list-item-text'>Content Management <br /> System</ListItemText>
                {openManage ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </ListItem>
            </ListItemButton>
            <Collapse in={openManage} timeout="auto" unmountOnExit>
              {contentTabs.map((tab) => (
                <List component="div" disablePadding key={tab.id} sx={{ paddingBottom: '0', paddingTop: '0' }}>
                  <ListItemButton onClick={() => handleNavigation(tab.route)} style={{ padding: '0' }}>
                    <ListItem className='list-item'>
                      <ListItemText disableTypography sx={{ fontSize: isDesktopDevice ? "0.769vw" : "0.688rem" }}>{tab.title}</ListItemText>
                    </ListItem>
                  </ListItemButton>
                </List>
              ))}
            </Collapse>
          </List>
        </div>
        <div>
          <List>
            <ListItemButton onClick={handleLogout}>
              <ListItem style={{paddingLeft: '0'}}>
                <ListItemIcon style={{ minWidth: '0', marginRight: isDesktopDevice ? '0.513vw' : '0.5rem' }}>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText style={{ fontSize: isDesktopDevice ? '1.026vw' : '0.688rem' }}>
                  Logout
                </ListItemText>
              </ListItem>
            </ListItemButton>
          </List>
        </div>
      </div>
    </Drawer>
  );
};

export default Sidebar;
